import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import NavbarLinks from './NavbarLinks';
import WidthLimiter from './WidthLimiter';

import NavbarLogo from '../img/navbar-logo.inline.svg';

import styles from './styles/Navbar.module.scss';

const Navbar = ({ location, dark }) => {
  return (
    <div className={classNames(styles.wrapper, dark && styles.wrapperDark)}>
      <WidthLimiter>
        <nav className={styles.navbar}>
          <div className={styles.brandwrap}>
            <div className={styles.brandtop}></div>
            <div className={styles.brandbottom}>
              <Link to="/" className={styles.logoLink}>
                <NavbarLogo />
              </Link>
            </div>
          </div>
          <div className={styles.navigation}>
            <NavbarLinks styles={styles} location={location} />
          </div>
        </nav>
      </WidthLimiter>
    </div>
  );
};

export default Navbar;
