import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import '../styles/global.scss';

import ContentModules from '../components/layout/ContentModules';
import FixedNavbar from '../components/FixedNavbar';
import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import SocialIcons from '../components/SocialIcons';
import WidthLimiter from '../components/WidthLimiter';

export default ({ data, location }) => {
  const modules = data.contentfulLayout.contentModules;

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="og:image" content="/og_image.jpg" />
        <meta name="og:title" content={data.site.siteMetadata.title} />
        <meta name="og:url" content={data.site.siteMetadata.url} />
        <meta name="og:type" content="website" />
      </Helmet>
      <Layout location={location}>
        <FixedNavbar location={location} hideUntil={600} />
        <Navbar location={location} dark={data.contentfulLayout.darkNavigation} />
        <ContentModules modules={modules} />
        <WidthLimiter>
          <SocialIcons />
        </WidthLimiter>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
    contentfulLayout(slug: { eq: "home" }) {
      darkNavigation
      contentModules {
        __typename
        ... on ContentfulLayoutBigVideo {
          id
          videoSource {
            file {
              url
            }
          }
        }
        ... on ContentfulLayoutGallery {
          id
          collection {
            title
            slug
            gallery {
              title
              ...ImageGridSizes
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          maxPhotos
        }
        ... on ContentfulLayoutCopy {
          id
          heading
          ctaTitle
          ctaLink
          visualStyle
          copy {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulLayoutArchive {
          id
          heading
          visualStyle
          collections {
            id
            title
            year
            slug
          }
        }
        ... on ContentfulLayoutVideoEmbeds {
          id
          videoLinks
          visualStyle
        }
        ... on ContentfulLayoutCollectionList {
          id
          collections {
            id
            title
            slug
            gallery {
              ...ImageGridSizes
            }
          }
          showCta
        }
      }
    }
  }
`;
